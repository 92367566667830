#spravax {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px
}
#spravay {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px
}

.sudivsm {
	float:left;width:calc(2vw + 24px) !important;height:calc(2vw + 24px) !important;border:1px solid gray;background-size:100%;background-size:cover;
}

.seldiv {
	position:relative;float:left;width:120px;height:120px;border:3px solid black;background-size:100%;opacity: 0.8;
}

.deviatka {
	border:3px solid #2C2C2C;width:33%;float:left	
}

.hracie_polesm {
	margin-top:10px;
  width:calc(90vw);
  margin-left:30px;
	margin-bottom:70px;
	/* margin:0 auto; */

  /* border:1px solid red; */
}

.selector {
	display:none;position:fixed;bottom:30%;left:30%;border:0px solid gray;width:310px;height:150px;z-index:50; opacity: 0.8;
}

.basicdiv {
	margin:0px;padding-top:10px;text-align:left;overflow:auto;
}

.statusdivcaption {
	position:relative;
	color:orange;
	font-size:1.3em;
	float:left;
	font-weight: bold;
	margin-right:5px;
}

#statusdiv {
	position:relative;
	color:silver;
	font-size:1.3em;
	float:left;
	white-space: pre-line;
}

.nadpis {
	font-size:1.6em;
	font-weight:bold;
}

@media screen and (max-width: 800px) {
	.kodiv {width:100px;top:150px;}
}