* { padding: 0; margin: 0; }

html, body {
  height: 100%;
  
  display: flex;
  flex-direction: column;
}

body > * {
  flex-shrink: 0;
}

.App {
  text-align: center;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;  
}

.okbtn {
  margin-top:20px;
  font-size:35px;
  border-radius:10px;
  padding-left:8px;
  padding-right:8px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  cursor:pointer;
  color:black;
}

.genInput {
  border-radius:10px;
  width:80%;
  text-align:center;
  box-shadow: rgb(204, 219, 232) 3px 3px 6px 5px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
  font-size:30px;
}

.del {
  position:relative;
  height:40px;
  top:-5px;
  width:30px;
  margin:4px;
  border-radius:10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  cursor:pointer;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
